<template>
    <div class="error-message blurred" v-if="errorMessage">
        <div class="error-message-content">
          <div class="error-message-header">
            <div class="error-message-title">
              <slot name="header">
                {{ 'Error' }}
              </slot>
            </div>
            <!-- <div class="error-message-icon">
              <img src="@/assets/live-channel/error.png" />
            </div> -->
          </div>
          <hr />
          <div class="error-message-body">
            <div class="error-message-text">
              <slot name="body">
                <!-- {{ errorMessage }} -->
              </slot>
              Unknown error
            </div>
          </div>
          <div class="error-message-footer">
            <slot name="footer"></slot>
            <div class="error-message-btns btn_one" @click="actNextChannelChange">
              {{ 'Next Channel' }}
            </div>
            <div class="error-message-btns btn_two" @click="actClosePlayer()">
              <span> {{ 'Close Player' }} </span>         
            </div>
          </div>
        </div>
      </div>
</template>
<script>
export default {
    name: 'Confirmation',
}
</script>
<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
  
.error-message {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #212121;
  opacity: 0.8;
  z-index: 999999999;
  display: flex;
  align-items: center;
  justify-content: center;
  .error-message-content {
    padding: 5rem;
    border: 1px solid #222222;
    background: #000000;
    .error-message-header {
      text-align: center;
    }
    hr {
      width: 90%;
      margin: auto;
    }
    .error-message-body {
      padding: 2rem 1rem;
      text-align: center;
    }
    .error-message-footer {
      display: flex;
      align-items: center;
      gap: 1rem;
      .error-message-btns {
        border-radius: 8px;
        background-color: #999;
        padding: 1rem 2rem;
        font-weight: bold;
        font-size: 0.9rem;
        cursor: pointer;
        &:hover {
          transform: scale(1.05);
        }
      }
      .btn_one {
        background: $primary-btn-color;
      }
      .btn_two {
        background: #ffffff;
        color: #000
      }
    }
  }
}
</style>